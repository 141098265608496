.threeCard {
	width: 100%;
	/* height: 400px; */
	box-sizing: border-box;
	background: var(--background);
	/* color: #fff; */
	overflow: hidden;
	padding-bottom: 168px;
	margin-top: 150px !important;
}
.GridFlex {
	width: 100%;
	max-width: 1120px;
	margin: auto;
	padding: 0 16px;
	position: relative;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;
	gap: 30px;
	justify-content: center;
	box-sizing: border-box;
}
.Child {
	/* box-shadow: 8px 8px 24px 0 rgba(9, 13, 20, 0.4),
		-4px -4px 8px 0 rgba(224, 224, 255, 0.04), 0 1px 1px 0 rgba(9, 13, 20, 0.4); */
	box-shadow: var(--surface-shadow) !important;
	background: var(--color-2);
	position: relative;
	top: 0px;

	border: solid 1px rgba(245, 247, 250, 0.06);
	backdrop-filter: blur(100px);
	display: block;
	padding: 24px;
	border-radius: var(--bdrad) !important;
	border: 2px solid transparent;
	transition: ease 0.5s !important;
}
#cardicon {
	color: var(--color-2);
}
/* #cardicon:hover {
	color: var(--btn);
} */
.ChIcon {
	background: var(--btn);
	width: 48px;
	height: 48px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	margin-bottom: 24px;
}
.ChCon {
	margin-top: 1rem;
	margin-bottom: 8px;
	white-space: nowrap;
	box-sizing: border-box;
	display: flex;
	align-items: flex-end;
	width: 100%;
}
.ChNum {
	/* font-family: cursive; */
	font-weight: 600 !important;
	color: var(--color-1);
	font-size: 40px !important;
	line-height: 48px;
}

.ChDesc {
	font-weight: 700 !important;
	text-transform: uppercase !important;
	color: var(--color-1);

	font-family: Montserrat, sans-serif;
	font-size: 14px !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.71 !important;
	letter-spacing: normal !important;
	margin-left: 2% !important;
}
.ChDesc2 {
	color: var(--color-1);
}
.Child2 {
	transform: translateY(-32px);
}
.ChIcon2 {
	background-color: #00bbff !important;
}
.ChIcon3 {
	background: #9c42f5;
}
.Child:hover {
	position: relative;
	border: 2px solid #39b54aff;

	top: -10px;
}
.icon3 {
	width: "24px";
	height: "24px";
	color: "#e0e0ff";
}
/* .Child:hover .ChDesc {
	color: var(--color-2) !important;
}
.Child:hover .ChDesc2 {
	color: var(--color-2) !important;
}
.Child:hover .ChNum {
	color: var(--color-2) !important;
} */

/* .Child:hover .ChIcon #cardicon {
	color: var(--btn) !important;
} */
/* .Child:hover .ChIcon {
	background-color: var(--color-2);
} */

.Child2:hover .ChIcon2 {
	color: #00bbff !important;
	background-color: #fff !important;
}
.Child3:hover .ChIcon3 {
	color: #9c42f5 !important;
	background-color: #fff !important;
}
/* .Child2:hover {
	background: var(--bgHover);
} */
/* .Child3:hover {
	background-image: linear-gradient(128deg, #0bf, #9c42f5 53%, #5d2de1);
}
.ChIcon:hover {
	background-color: #fff !important;
} */
@media screen and (max-width: 1200px) {
	.GridFlex {
		grid-gap: 20px !important;
		gap: 20px !important;
	}
}

@media screen and (max-width: 900px) {
	.Child {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.GridFlex {
		overflow: hidden;
		/* padding-left: 16px !important;
    padding-right: 16px !important; */
		grid-template-columns: 1fr !important;
		grid-template-rows: repeat(3, auto) !important;
		padding-top: 32px;
		padding-bottom: 42px;
	}
	.threeCard {
		grid-template-columns: 1fr !important;
		grid-template-rows: repeat(3, auto) !important;
		padding-top: 32px !important;
		padding-bottom: 42px !important;
	}
	.Child2 {
		transform: translateY(0px);
	}
}
