* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.o1a {
	/* right: 0px; */
	left: 0;
	top: 300px;
	width: 200px;
	height: 200px;
	/* background: rgb(111, 111, 116); */
	background: var(--bgHover);
	position: absolute;
	filter: var(--hira);
	-webkit-filter: var(--hira);

	border-radius: 50%;
	/* border: 1px solid #000; */

	/* z-index: -10 !important; */
}
.o2 {
	right: 100px;
	width: 250px;
	height: 250px;
	bottom: 5%;
	background: rgb(111, 111, 116);
	background: var(--bgHover);
	position: absolute;
	filter: var(--hira);
	-webkit-filter: var(--hira);
	border-radius: 50%;

	/* z-index: -10 !important; */
}
.o3 {
	filter: var(--hira);
	-webkit-filter: var(--hira);
	right: 130px;
	top: 10%;
	width: 200px;
	height: 200px;
	background: rgb(111, 111, 116);
	background: var(--bgHover);
	position: absolute;

	border-radius: 50%;

	/* z-index: -10 !important; */
}
#Bigbox {
	background-repeat: no-repeat;
	background-position: 155% -70%;
	/* background-color: aqua; */
	margin: 0;
	padding-top: 0;
	background-color: var(--background);
	position: relative;
}
.headertexttwo {
	/* color: var(--color-1); */
	color: var(--btn);
	font-weight: 600;
}

#icon {
	color: var(--color-2);
}
.headerbtn {
	border-radius: var(--bdrad) !important;
	text-decoration: none;
	margin-bottom: 2%;
	color: var(--color-2) !important;
	font-weight: bold !important;
	font-size: 18px;
	/* background-image: linear-gradient(114deg, #0fa, #4579f5 53%, #9c42f5); */
	background: var(--btn) !important;
	width: 100%;
	box-shadow: var(--surface-shadow) !important;
}
.headerbtn2 {
	border-radius: var(--bdrad) !important;
	text-decoration: none;
	margin-bottom: 2%;
	color: var(--btn) !important;
	font-weight: bold !important;
	font-size: 18px;
	border: 2px solid var(--btn) !important;
	/* border-color: red; */
	/* background-image: linear-gradient(120deg, #2d2f33, #282a2e 51%, #212429 99%); */
	width: 100%;
	box-shadow: var(--surface-shadow) !important;

	/* box-shadow: 0 8px 8px -4px rgb(69 121 245 / 12%),
		0 16px 24px 0 rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
		0 0 1px 0 rgb(69 121 245 / 24%); */
}
.headerbtn:hover {
	box-shadow: "0 8px 8px -4px rgb(69 121 245 / 12%), 0 16px 24px 0 rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%), 0 0 1px 0 rgb(69 121 245 / 24%);";
}
#icon1 {
	color: var(--color-2) !important;
}
#telfonrasm {
	/* width: 230px; */
	width: 400px !important;
	position: relative;
	/* border: 1px solid #000; */
	top: -42px;
	left: 105px;
	/* box-shadow: 5px 5px 5px 5px #000; */
	/* border: 1px solid #000; */
	border-bottom-right-radius: 1000px;
}
@media screen and (width: 1024px) {
	#telfonrasm {
		width: 350px !important;
	}
}
#telyon {
	width: 271px;
	height: 428px;
}
.swip {
	position: absolute;
	bottom: -60px;
	left: -20px;
	animation: App-logo-spin infinite 20s linear;
}
@media screen and (min-width: 1441px) {
	#Bigbox {
		background-repeat: no-repeat;
		background-position: 93% -50%;
	}
	#telfonrasm {
		width: 115%;
	}
	#telyon {
		padding: 32px;
	}
}
@media screen and (min-width: 1366px) {
	#Bigbox {
		background-repeat: no-repeat;
		background-position: 91% -24%;
	}
}
@media screen and (max-width: 1440px) {
	#telfonrasm {
		width: 115%;
	}
	#telyon {
		width: 271px;
		height: 428px;
	}
}

@media screen and (max-width: 1030px) {
	.o2 {
		right: 0px;
		width: 250px;
		height: 250px;
		bottom: 5%;
	}
	.o3 {
		right: 250px;
		width: 200px;
		height: 200px;
	}
	.o1a {
		left: -15%;
		top: 70%;
		width: 250px;
		height: 250px;
	}

	#telyon {
		position: absolute;
		top: 50px;
		width: 240px;
		height: 400px;
	}
	#telfonrasm {
		position: relative;
		top: -1px;
	}
	.swip {
		width: 108px;
		position: absolute;
		bottom: 20px;
	}
}
#boshtex {
	color: var(--textbold);
	-webkit-text-stroke: var(--textbold-border);
	text-shadow: var(--textbold-shadov);
	font-weight: 800;
	line-height: 1;
	max-width: "100%";
}
#burgut {
	width: 14%;
}

@media screen and (max-width: 768px) {
	.o3 {
		right: 0px;
		top: 25%;
		width: 150px;
		height: 150px;
	}
	.o1a {
		left: -150px;
		top: 370px;
		width: 350px;
		height: 350px;
	}
	#Bigbox {
		background-size: 330px;
		background-position: right -20% center;
		min-height: auto;
		/* padding-top: 80px; */
	}
	#telfonrasm {
		width: 190px;
		position: relative;
		top: 0px;
		left: 105px;
	}
	#telyon {
		width: 150px;
		height: 240px;
		position: absolute;
		top: 20px;
	}
	.img1 {
		width: 40px;
	}
	.img2 {
		width: 35px;
	}
	.img3 {
		width: 70px;
	}
	.swip {
		position: absolute;
		left: 50px;
		bottom: -150px;
	}
}
@media screen and (max-width: 430px) {
	.o1a {
		left: -100px;
		top: 500px;
		width: 250px;
		height: 250px;
	}
	.o2 {
		right: 0px;
		width: 0px;
		height: 0px;
		bottom: 5%;
	}
	#burgut {
		margin: 0 2px;
		width: 32px;
	}
	#telfonrasm {
		width: 340px !important;
		position: relative;
		left: 15%;
		top: 30px;
	}
	#telyon {
		position: absolute;
		left: 125px;
	}
	.img3 {
		position: absolute;
	}
	.swip {
		position: absolute;
		bottom: -57px;
		left: 50px;
	}
}
@media screen and (max-width: 380px) {
	#telfonrasm {
		width: 270px !important;
		position: relative;
		top: 30px;
	}
	.o3 {
		top: 45%;
		width: 150px;
		height: 150px;
	}
}
@media screen and (min-width: 2555px) {
	.o2 {
		left: 78%;
		width: 250px;
		height: 250px;
		bottom: 10%;
	}
	.o3 {
		left: 75%;
		width: 200px;
		height: 200px;
	}
	.o1a {
		left: 20%;
		width: 200px;
		height: 200px;
	}
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
