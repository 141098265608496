body {
	background: var(--background);
}
.GetFooter {
	width: 100% !important;
}
.GetFooCon {
	width: 100% !important;
	background: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
@media screen and (min-width: 901px) {
	.GetFooter {
		margin-top: 200px;
		background: var(--foo);
	}
}
@media screen and (max-width: 900px) {
	.GetFooCon {
		overflow: hidden !important;
		padding: 0px !important;
	}
}
