.GetUPPother {
	display: flex;
	flex-direction: column;
	/* border: 1px solid #000; */
}
#karta {
	width: 100%;
}
.TopPartG {
	background-image: linear-gradient(
		101deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);

	-webkit-backdrop-filter: blur(40px);
	backdrop-filter: blur(40px) !important;
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
	padding: 40px !important;
	box-sizing: border-box !important;
	position: relative;
	/* top: -80px; */
	/* border-top-left-radius: 35px;
	
	border-top-right-radius: 35px; */
	border-radius: var(--bdrad) !important;
	box-shadow: var(--surface-shadow);
	background: var(--color-2);
}
.GetImg001 {
	margin: 10px;
	width: 130px;
}
.telicon {
	margin: 10px;
	width: 60px;
}

.linecod {
	border: 1px solid #000;
	position: absolute;
	right: 39%;
	top: -45% !important;
	transform: rotate(285deg);
}
.TextCenBoxG {
	flex-grow: 1 !important;
	font-size: 26px !important;
	font-weight: 700 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1 !important;
	letter-spacing: -1px !important;
	color: var(--color-1) !important;
	margin-bottom: 8px !important;
	display: flex;
	align-items: center;
}
#manzil {
	font-size: 16px;
}
.LeftImgBoxG {
	display: flex;
	width: 300px;
	flex-wrap: wrap;
	justify-content: center;
}
.BottomPartG {
	box-shadow: var(--surface-shadow);

	position: relative;
	/* top: -110px; */
	padding: 40px 40px 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	/* border-bottom-right-radius: 35px;
	border-bottom-left-radius: 35px; */
	border-radius: var(--bdrad) !important;

	/* background: linear-gradient(113deg, #0fa, #4579f5 53%, #9c42f5); */
	background: rgb(190, 190, 190);
	background: linear-gradient(
		66deg,
		rgba(190, 190, 190, 0.6674019949776786) 28%,
		rgba(193, 193, 193, 1) 56%,
		rgba(125, 255, 142, 1) 99%
	);
}
.ImgPhoneG {
	height: 480px !important;
	/* display: block; */
}

.GetUlLI {
	list-style: none;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: center; */
}

.LInameWon::before {
	content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGc+CiAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgPGc+CiAgICAgICAgICAgICAgICAgICAgICAgIDxnPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHBhdGggZD0iTTAgMEgyNFYyNEgweiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTY0MCAtNTA0MikgdHJhbnNsYXRlKDAgNDYxMikgdHJhbnNsYXRlKDE2NSA3MikgdHJhbnNsYXRlKDQ3NSAzNTIpIHRyYW5zbGF0ZSgwIDYpIi8+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xMC4zOTUgMTkuMzgzYy40NjggMCAuODM1LS4xODggMS4wOTMtLjU3TDE4LjgwMSA3LjUzYy4xODctLjI4MS4yNjUtLjUzOS4yNjUtLjc4MSAwLS42NC0uNDY4LTEuMTAyLTEuMTI1LTEuMTAyLS40NTMgMC0uNzI2LjE1Ny0xIC41OTRsLTYuNTc4IDEwLjQwNi0zLjM2LTQuMjM0Yy0uMjY1LS4zMi0uNTM4LS40NjktLjkyOS0uNDY5LS42NjQgMC0xLjE0LjQ3LTEuMTQgMS4xMTcgMCAuMjgyLjA5My41NDcuMzM2LjgyOWw0LjAzOSA0Ljk2Yy4zMDQuMzY4LjY0LjUzMiAxLjA4Ni41MzJ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjQwIC01MDQyKSB0cmFuc2xhdGUoMCA0NjEyKSB0cmFuc2xhdGUoMTY1IDcyKSB0cmFuc2xhdGUoNDc1IDM1MikgdHJhbnNsYXRlKDAgNikiLz4KICAgICAgICAgICAgICAgICAgICAgICAgPC9nPgogICAgICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgICAgIDwvZz4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+Cg==");
	vertical-align: middle;
	color: var(--color-1);
}
.LInameWon {
	opacity: 0.6 !important;
	padding-left: 1em !important;
	font-size: 20px !important;
	opacity: 0.8 !important;
	/* margin-bottom: 16px !important; */
	color: var(--color-2);
}
.Available {
	opacity: 0.6;
	color: inherit;
	/* margin-top: -20px !important; */
}
#imgGrid {
	display: inline;
}
@media screen and (max-width: 1025px) {
	.TopPartG {
		padding: 40px !important;
	}
	.GetImg001 {
		margin: 10px;
		width: 100px;
	}
	#karta {
		width: 100%;
		height: 100%;
		margin-top: 20%;
	}
}
@media screen and (max-width: 900px) {
	.TopPartG {
		display: flex !important;
	}
	#imgGrid {
		padding-top: 10px;
	}
	#karta {
		width: 100%;
		height: 100%;
		margin-top: 0;
	}
	.LInameWon {
		font-size: 15px !important;
		opacity: 1 !important;
		color: var(--color-1);
	}

	.BottomPartG {
		display: flex;
		flex-direction: column-reverse;
	}
	.GetImg001 {
		width: 80px;
	}
}
@media screen and (max-width: 530px) {
	#manzil {
		font-size: 14px;
	}
	.TopPartG {
		display: flex !important;
		flex-wrap: wrap;
		padding: 20px !important;
	}
	.ImgPhoneG {
		width: 80vw !important;
		height: auto !important;
	}
	.GetImg001 {
		width: 60px;
	}
}
.geeks {
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	padding: 0 !important;
}

.geeks img {
	width: 100%;
	transition: 0.5s all ease-in-out;
}

.geeks:hover img {
	transform: scale(1.2);
}
