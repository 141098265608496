.Foot {
	/* padding-top: 8px; */
	padding-bottom: 8px;
	margin-top: 80px !important;
	/* z-index: 100; */
	position: relative;
	background: transparent !important;
}

.bg {
	width: 100%;
	background: red;
	/* background: red !important; */
}
.linkbold {
	font-size: 20px !important;
	font-weight: bold !important;
	text-decoration: none !important;
	color: #000 !important;
}
#fooicon {
	color: #000 !important;
	transition: 1s;
}
#fooicon:hover {
	transform: scale(1.5);
}
.linkold {
	font-size: 17px !important;
	text-decoration: none !important;
	color: #000 !important;
}
.linkold:hover {
	color: var(--btn) !important;
}
.linkbold:hover {
	color: var(--btn) !important;
}
@media (max-width: 912px) {
	.Foot {
		background-color: var(--background);
	}
}
.footer {
	/* border-radius: 24px; */
	border-radius: var(--bdrad) !important;

	background-color: var(--color-2);
	/* background-color: green; */

	box-shadow: var(--surface-shadow);

	/* background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.6674019949776786) 0%,
		rgba(64, 60, 52, 0.45451684091605393) 100%
	); */
	display: flex;
	flex-direction: column;
	gap: 65px;
	justify-content: center;
	padding-top: 48px !important;
	/* border: 1px solid #000; */
}
#fooicon {
	color: var(--btn);
}
.DecimalT {
	color: var(--color-1);
}
.css-w7kkdf-MuiTypography-root {
	margin-top: 200% !important;
}
@media (max-width: 1025px) {
	.linkbold {
		font-size: 18px !important;
	}
}
@media (max-width: 325px) {
	.linkbold {
		font-size: 16px !important;
	}
}
@media screen and (max-width: 900px) {
	.DecimalT {
		position: absolute;
		bottom: 0;
		width: 88%;
		display: flex;
		justify-content: space-between;
		right: 30px;
	}
	#DECIMAL {
		margin-left: 15px;
	}
}
.footertext {
	color: var(--color-1);

	font-size: 14px;
}
.COMPANYTEXT Typography {
	margin-bottom: 20px !important;
}
.foottext {
	display: flex;
	color: var(--color-1);

	margin-bottom: 20px !important;
	gap: 10px;
}
.margin {
	margin-bottom: 20px !important;
	color: var(--color-1);
}
.css-1mikpln-MuiButtonBase-root-MuiAccordionSummary-root {
	background-color: #212429 !important ;
}
.css-blpw0b-MuiButtonBase-root-MuiAccordionSummary-root {
	background-color: #212429 !important ;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
	background: #212429 !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
	box-shadow: none !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
	position: unset !important;
}
.css-i4bv87-MuiSvgIcon-root {
	color: var(--color-2);
}
.css-i4bv87-MuiSvgIcon-root {
	color: var(--color-2);
}
.color {
	color: var(--color-1);
}
.color2 {
	color: var(--color-1);
}
