* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
#meetthebg {
	padding-top: 9%;
	background: var(--background);
	/* background: rgb(0, 0, 0);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.9923319669664741) 52%,
		rgba(25, 83, 1, 1) 100%
	); */
}
#meetdiv {
	z-index: 1;
	position: relative;
}
#shar1 {
	background: rgb(111, 111, 116);
	background: var(--bgHover);
	position: absolute;
	width: 400px;
	height: 400px;
	border-radius: 50%;
	left: -300px;
	top: 210px;
	z-index: -10 !important;
	filter: var(--hira);
	-webkit-filter: var(--hira);
}
#shar2 {
	background: rgb(111, 111, 116);
	background: var(--bgHover);
	position: absolute;
	width: 200px;
	height: 200px;
	border-radius: 50%;
	right: -12% !important;
	top: 0%;
	z-index: -10 !important;
	filter: var(--hira);
	-webkit-filter: var(--hira);
}

.leftGrid {
	width: 100%;
	padding: 28px;

	height: 100%;
	display: flex;
	/* border: 1px solid #000; */
	flex-direction: column !important;
	gap: 30px;
	/* font-size: 40px; */
	/* justify-content: center; */
	/* backdrop-filter: blur(100px); */
	align-items: flex-start;
	background: var(--color-2);
	border-radius: var(--bdrad) !important;
	position: relative;
	top: 0;
	transition: ease 0.5s !important;
	border: 2px solid transparent;
}
.leftGrid:hover {
	position: relative;
	top: -10px;
	border: 2px solid #39b54aff;
}
#textbold {
	font-size: 32px;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	letter-spacing: -1px;
	text-align: start !important;
	color: var(--color-1);
}
@media screen and (max-width: 1024px) {
	#shar2 {
		right: 0% !important;
	}
	#textbold {
		font-size: 22px;
		text-align: start !important;
	}
}
@media screen and (max-width: 769px) {
	#textbold {
		font-size: 24px;
		width: 100%;
		text-align: start !important;
	}
	#textdes {
		text-align: start !important;
		/* font-weight: bold !important; */
	}
}
@media screen and (max-width: 425px) {
	#textname {
		font-size: 15px !important;
	}
	#textdesss {
		font-size: 10px !important;
	}
}
#textdes {
	font-size: 16px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: var(--color-1);
	opacity: 0.6;
	text-align: start !important;
	/* font-weight: bold !important; */
}

#www {
	width: 100%;
	color: var(--color-1);
	height: 100% !important;
	background: var(--color-2);
	border: 2px solid transparent;

	justify-content: start;
	grid-gap: 16px;
	display: flex;
	padding: 28px;
	border-radius: var(--bdrad) !important;
	text-transform: none;

	align-items: flex-start;

	transition: ease 0.5s !important;
	position: relative;
	bottom: 0;
}
#www:hover {
	bottom: 10px;
	border: 2px solid #39b54aff;
	/* box-shadow: 10px 15px 10px 0px rgb(156, 151, 151) !important; */
}
/* #www:hover #textname {
	color: var(--color-2);
}
#www:hover #textdesss {
	color: var(--color-2);
} */
#textname {
	font-size: 18px;
	line-height: 1.4;
	letter-spacing: -0.4px;
	color: var(--color-1);
	text-transform: none;
	display: flex;
	align-items: center;
	gap: 5px;
	font-weight: 600;
}
#textdesss {
	opacity: 0.6;
	font-size: 16px;
	line-height: 1.43;
	letter-spacing: normal;
	/* color: #e0e0ff;
   */
	color: var(--color-1);

	font-family: Montserrat, sans-serif;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
}
