#o1 {
	/* right: 0px; */
	left: -150px;
	top: 300px;
	width: 350px;
	height: 350px;
	/* background: rgb(111, 111, 116); */
	background: var(--bgHover);
	position: absolute;

	filter: blur(5px);
	-webkit-filter: blur(5px);

	border-radius: 50%;
	/* border: 1px solid #000; */

	z-index: -10 !important;
}
#o2 {
	right: 0px;
	width: 250px;
	height: 250px;
	bottom: 5%;
	background: rgb(111, 111, 116);
	background: var(--bgHover);
	position: absolute;
	filter: blur(5px);
	-webkit-filter: blur(5px);
	border-radius: 50%;

	z-index: -10 !important;
}
#o3 {
	filter: blur(5px);
	-webkit-filter: blur(5px);
	right: 130px;
	top: 10%;
	width: 200px;
	height: 200px;
	background: rgb(111, 111, 116);
	background: var(--bgHover);
	position: absolute;

	border-radius: 50%;

	z-index: -10 !important;
}
#loginbox {
	width: 50% !important;
	height: 50% !important;
	/* margin: 10% auto !important; */
	/* border: 1px solid #000 !important; */
}
#inputiphon {
	padding: 200px !important;
}
#inputname {
	padding: 10px;
}
.phonew {
	color: red;
	padding: 10px;
}

#inputtext {
	padding: 10px !important;
	width: 100% !important;
}
#inputtexttel {
	padding: 30px !important;
}
#loginbox {
	margin: 10% auto !important;
	width: 25% !important;
}
#ogoh {
	color: red;
	font-size: 14px;
}
#yozuv {
	height: 70px !important;
}
#loginbtn1 {
	border-radius: var(--bdrad) !important;
	text-decoration: none;
	margin-bottom: 2%;
	color: var(--btn) !important;
	font-weight: bold !important;
	font-size: 15px;
	border: 2px solid var(--btn) !important;
	/* border-color: red; */
	/* background-image: linear-gradient(120deg, #2d2f33, #282a2e 51%, #212429 99%); */
	width: 100%;
	box-shadow: var(--surface-shadow) !important;
	text-transform: capitalize;
}
#loginbtn {
	border-radius: var(--bdrad) !important;
	text-decoration: none;
	margin-bottom: 2%;
	color: var(--color-2) !important;
	font-weight: bold !important;
	font-size: 15px;
	text-transform: capitalize;
	/* background-image: linear-gradient(114deg, #0fa, #4579f5 53%, #9c42f5); */
	background: var(--btn) !important;
	width: 100%;
	box-shadow: var(--surface-shadow) !important;
}
@media screen and (min-width: 1025px) {
	#loginbox {
		width: 25% !important;
	}
}
@media screen and (max-width: 1024px) {
	#loginbox {
		width: 35% !important;
	}
}
@media screen and (max-width: 769px) {
	#loginbox {
		width: 50% !important;
	}
}
@media screen and (max-width: 427px) {
	#loginbox {
		width: 90% !important;
	}
}
