.TableBody01 {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 0 32px !important;
	box-sizing: border-box !important;
}
#uquvyili {
	color: var(--textbold);
	-webkit-text-stroke: var(--textbold-border-2);
	text-shadow: var(--textbold-shadov);
}

#tablebox {
	width: 100%;
	background: var(--background);

	padding-bottom: 100px;
}
#oyicon {
	background: var(--color-2);
	box-shadow: 1px 1px 2px rgb(92, 87, 87);

	padding: 5px;
	border-radius: 50%;
	transition: 0.5s;
}
#oyicon:hover {
	transform: scale(1.5);
}
.TableBody {
	width: 1056px !important;
	position: relative;
}
#oquv {
	padding: 0;
}
@media screen and (max-width: 900px) {
	.TableBody01 {
		overflow: auto;
		white-space: nowrap;
		padding-left: 300px !important;
	}
	.TableBody {
		width: 1026px !important;
		margin: 50px 20px;
	}
}
@media screen and (max-width: 430px) {
	.TableBody01 {
		overflow: auto !important;
		white-space: nowrap !important;
		justify-content: start;
		padding-left: 0 !important;
	}
	.TableBody {
		width: 1026px !important;
	}
	#oquv {
		padding: 10px;
	}
}
@media screen and (max-width: 1025px) {
	.TableBody01 {
		overflow: auto;
		white-space: nowrap;
	}
	.TableBody {
		width: 100%;
		margin: 50px 0;
	}
}
