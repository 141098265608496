* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
#btntext {
	color: var(--color-1);
}
.navtext1 {
	color: var(--color-1);
	font-weight: 600;
	font-size: 20px;
	text-decoration: none !important;
	text-transform: none;
	border-bottom: 2px solid transparent !important;
}
#navtext1:hover {
	background: none;
}
#pastkiqism {
	position: relative;
	width: 255px;
	padding: 16px 0;

	box-shadow: var(--surface-shadow);
	border: var(--surface-border) !important;
	background: var(--background);

	/* -webkitbackdropfilter: blur(40px) !important; */
	/* backdrop-filter: blur(40px) !important; */
	display: flex;
	flex-direction: column;
	top: 30px;
	left: -90;
	margin: 0;
	border-radius: 24px;
	z-index: 2;
}
.li {
	cursor: pointer !important;
}
.li:hover {
	background: transparent !important;
}
.li:active {
	background: transparent !important;
}
.active {
	color: var(--color-1);
	font-weight: 600;
	font-size: 20px;
	text-decoration: none !important;
	border-bottom: 2px solid #39b54aff !important;
}
#iconcolor {
	color: var(--color-1);
}
.tilfont {
	font-size: 16px !important;
}
@media screen and (max-width: 1024px) {
	.navtext1 {
		font-size: 16px;
	}
	.tilfont {
		font-size: 16px;
	}
}
