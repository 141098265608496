* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
#chartname {
	width: 130px;
}
#javaboxw {
	transform: scale(0.8);
}
@media screen and (max-width: 430px) {
	#chartname {
		width: 60px !important;
	}
}
@media screen and (max-width: 770px) {
	#chartname {
		width: 100px;
	}
}
@media screen and (max-width: 1025px) {
	#chartname {
		width: 130px;
	}
}
@media screen and (min-width: 1026px) {
	#chartname {
		width: 130px;
	}
}
