.Team {
	padding-top: 40px;
	box-sizing: border-box;
	background-color: var(--background);
	z-index: 0;
}

.pos {
	position: absolute;
	z-index: 1;
	color: red;
	font-size: large;
}
.fonbox1 {
	display: block;
	position: absolute;
	width: 194px;
	height: 194px;
	border-radius: 50%;
	/* border: 1px solid #000; */
	z-index: 1;
	top: 70px;
	left: -177px;
	background: var(--bgHover);
	filter: var(--hira);
	-webkit-filter: var(--hira);
}
.fonbox2 {
	display: block;
	position: absolute;
	width: 240px;
	height: 240px;
	border-radius: 50%;
	z-index: 1;
	bottom: 40%;
	left: 38%;
	background: var(--bgHover);
	filter: var(--hira);
	-webkit-filter: var(--hira);
}
.fonbox3 {
	display: block;
	position: absolute;
	width: 196px;
	height: 196px;
	border-radius: 50%;
	z-index: -1;
	bottom: 15%;
	right: 17%;
	background: var(--bgHover);
	filter: var(--hira);
	-webkit-filter: var(--hira);
}

.fonbox4 {
	display: block;
	position: absolute;
	width: 148px;
	height: 148px;
	border-radius: 50%;
	z-index: 1;
	top: 23%;
	right: -11%;
	background: var(--bgHover);
	filter: var(--hira);
	-webkit-filter: var(--hira);
}
@media screen and (max-width: 1024px) {
	.fonbox4 {
		right: 0% !important
		;
	}
}
.CardImagesL {
	border-top-right-radius: var(--bdrad);
	border-top-left-radius: var(--bdrad);
	width: 100% !important;
	/* border-radius: 10px; */
}
.fonbox5 {
	display: block;
	position: absolute;
	width: 98px;
	height: 98px;
	border-radius: 50%;
	z-index: 1;
	bottom: 22%;
	background: var(--bgHover);
	filter: var(--hira);
	-webkit-filter: var(--hira);
}

.TeamBOD {
	width: 100% !important;
	max-width: 1120px !important;
	margin: auto !important;
	padding-left: 32px !important;
	padding-right: 32px !important;
	position: relative !important;
	display: flex !important;
	flex-direction: column !important;
	position: relative;
	z-index: 2;
	border-radius: var(--bdrad) !important;

	/* border: 1px solid #fff; */
}
.TeamBOD2 {
	width: 100% !important;
	max-width: 1120px !important;
	margin: auto !important;
	padding: 0 25px !important;
	position: relative !important;

	border-radius: var(--bdrad) !important;
	z-index: 2;

	/* border: 1px solid #fff; */
}

.TeamCardPart {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 24px;
	z-index: 5;
}
.TeamCards {
	border-radius: var(--bdrad) !important;
	background: var(--color-2);
	position: relative;
	padding: 0;
	top: 0px;
	border: 2px solid var(--color-2);
	transition: ease 0.5s !important;
}
.TeamCards2 {
	border-radius: var(--bdrad) !important;
	background: var(--color-2);
	position: relative;
	padding: 0;
	top: 0px;
	border: 2px solid var(--color-2);
	transition: ease 0.5s !important;
	margin: 10px !important;
}

.TeamCards:hover {
	position: relative;
	top: -10px;
	border-color: #39b54aff;
}

.CardIconL {
	background-color: #0a67c2;
	border: 2px solid #383a3d;
	border-radius: 50%;
	padding: 8px;
	left: 99px;
	position: absolute !important;
}
.CardBodyLL {
	margin-top: 1em;
}
.CardNameL {
	color: var(--color-1) !important;
	font-size: 20px !important;
	font-weight: 600 !important;
	line-height: 1.6 !important;
	font-stretch: normal !important;
	letter-spacing: -0.4px !important;
	height: 64px;
}
.CardPosLL {
	margin-bottom: 16px !important;
	font-weight: 500 !important;
	opacity: 0.6;
	color: var(--color-1) !important;
}
.CardDescLL {
	margin-bottom: 16px !important;
	font-weight: 500 !important;
	color: var(--color-1);
	opacity: 0.6;
	font-size: 16px;
	font-size: 16px !important;
}
.TeamTextPartlll {
	box-sizing: border-box;
	text-align: center !important;
}
.TeamTExt1 {
	display: inline-block !important;
	background-color: rgba(0, 187, 255, 0.03) !important;
	/* border-radius: 12px !important; */
	border-radius: var(--bdrad) !important;
	text-align: center !important;
	align-items: center !important;
	padding: 4px 8px !important;
	color: var(--btn) !important;

	line-height: 18px !important;
	margin-bottom: 10px !important;
}
.ustozyozuv {
	height: 50% !important;
}
.TeamText33 {
	font-size: 36px !important;
	font-weight: 700 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1 !important;
	letter-spacing: -1px !important;
	color: var(--textbold);
	-webkit-text-stroke: var(--textbold-border-2);
	text-shadow: var(--textbold-shadov);
	margin-bottom: 15px !important;
}
@media screen and (min-width: 901px) {
	.TeamTextPartlll {
		text-align: center !important;
	}
	.TeamCards {
		/* box-shadow: 8px 8px 24px 0 rgba(9, 13, 20, 0.4),
			-4px -4px 8px 0 rgba(224, 224, 255, 0.04),
			0 1px 1px 0 rgba(9, 13, 20, 0.4);
		border: solid 1px rgba(245, 247, 250, 0.06); */
		box-shadow: var(--surface-shadow) !important;

		background-image: linear-gradient(
			101deg,
			rgba(245, 247, 250, 0.12),
			rgba(245, 247, 250, 0.06) 52%,
			rgba(245, 247, 250, 0)
		);
		-webkit-backdrop-filter: blur(40px);
		backdrop-filter: blur(40px);
		padding: 23px;
	}
	.Team {
		padding-bottom: 50px;
		margin-bottom: 50px;
	}
}
@media screen and (max-width: 900px) {
	.CardPosLL {
		margin-bottom: 13px !important;
	}
	.CardDescLL {
		font-size: 14px !important;
	}
	.TeamText33 {
		font-size: 27px !important;
	}
	.TeamCardPart {
		grid-template-columns: 1fr !important;
	}
	.TeamBOD {
		box-sizing: border-box;
		overflow: hidden !important;
		padding-left: 16px !important;
		padding-right: 16px !important;
	}
	.fonbox1 {
		width: 0;
		height: 0;
	}
	.fonbox2 {
		width: 240px !important;
		height: 240px !important;
		bottom: 37.5% !important;
		left: 80% !important;
	}
	.fonbox3 {
		bottom: 0;
		top: 100%;
	}
	.fonbox4 {
		width: 74px !important;
		height: 74px !important;
	}
	.fonbox5 {
		width: 0;
		height: 0;
	}
}
@media screen and (max-width: 1024px) {
	.Team {
		margin-bottom: 0px;
	}
	.fonbox3 {
		bottom: 0;
		top: 300%;
	}
}
@media screen and (max-width: 430px) {
	.CardPosLL {
		font-size: 13px !important;
	}
	.fonbox3 {
		top: 100%;
		right: 0%;
	}
	.fonbox4 {
		width: 74px !important;
		height: 74px !important;
		display: none;
	}
}
