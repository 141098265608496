:root {
	--surface-border: solid 1px rgba(245, 247, 250, 0.06);
	--background-2: #01801c;
	--background-navtab: rgba(112, 112, 125, 0.966);
	--blue: #0bf;
	--color-3: #3ab54a;
	--color-4: #030303;
	--color-5: #494d55;

	--btn: #3ab548ff;
	--bgHover: linear-gradient(
		223deg,
		rgba(58, 181, 72, 1) 40%,
		rgba(58, 181, 72, 1) 40%
	);
	--shar: blur(100px);
	--textbold: #3ab448ff;
	--textbold-border: 0px black;
	--textbold-border-2: 0px black;
	--textbold-shadov: 0px;
	--background: #fffffffe;
	--color-2: #f8f8f8fe !important;
	--color-1: #000;
	--hira: blur(0.5px);
	--bdrad: 5px;
	--foo: #fffffffe;
}

body {
	margin: 0;

	font-family: "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.css-19kzrtu {
	padding: 24px 0 !important;
}

.css-1vyamtt-MuiStepLabel-labelContainer {
	color: white !important;
	font-size: 20px;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-completed {
	color: white !important;
	font-size: 20px;
}

.css-1hv8oq8-MuiStepLabel-label.Mui-active {
	color: white !important;
	font-size: 20px;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
	color: #3ab548ff !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
	color: #3ab548ff !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
	position: absolute;
}
* {
	/* background-color: var(--background); */
	scrollbar-width: thin;
	scrollbar-color: #3ab548ff #f5f5f5;
}
*::-webkit-scrollbar {
	width: 8px;
}
*::-webkit-scrollbar-track {
	background: var(--background);
	/* border-radius: 20px; */
}
*::-webkit-scrollbar-thumb {
	background-color: #3ab548ff;
	border-radius: 20px;
	/* border: 3px solid #f5f5f5; */
}
::-webkit-scrollbar-thumb:horizontal {
	background: #000;
	height: 2px !important;
	padding: 0 !important;
	font-size: 2px;
}
html {
	scroll-behavior: smooth !important;
}
