* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.navbarbig {
	width: 100%;
	background: transparent;
	display: flex;
	position: fixed;
	z-index: 100 !important;
	color: white;
	padding: 10px 28px;
	align-items: center;
	justify-content: space-between;
	/* border: 1px solid lime; */
	transition: 0s;
}

.navbarbig.aktiv {
	/* background-image: linear-gradient(
		101deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);
	backdrop-filter: blur(30px);
	background: hsla(0, 0%, 100%, 0.162); */
	background: var(--background);
	box-shadow: 0.1px 0px 2px 0.1px rgb(103, 102, 102);
}
.midlenav {
	width: 100%;
	/* border: 1px solid #0ff; */
	display: flex;
	justify-content: space-between;
	align-items: center !important;
	margin-left: 52px;
}
.leftnavbar {
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 38px;
}
#til {
	background: var(--background);
	color: var(--color-1) !important;
}
@media screen and (max-width: 1024px) {
	.midlenav {
		margin-left: 18px;
		display: flex;
		align-items: center;
	}
	.leftnavbar {
		gap: 20px;
		display: flex;
		align-items: center;
	}

	.navbarbig {
		padding: 0;
	}
}
@media screen and (max-width: 2560px) {
}
@media screen and (max-width: 900px) {
	.navbarbig {
		padding: 0;
		background-image: linear-gradient(
			101deg,
			rgba(245, 247, 250, 0.05),
			rgba(245, 247, 250, 0.011) 52%,
			rgba(245, 247, 250, 0)
		);
		backdrop-filter: blur(20px);
		background: hsla(0, 0%, 100%, 0.071);
	}
	.btnnav {
		width: 100%;
		box-shadow: 0 8px 8px -4pxrgba (69, 121, 245, 0.12),
			0 16px 24px 0rgba (69, 121, 245, 0.24),
			0 2px 4px -1pxrgba (27, 10, 82, 0.12), 0 0 1px 0rgba (69, 121, 245, 0.24);
		background-image: linear-gradient(
			114deg,
			#0fa,
			#4579f5 53%,
			#9c42f5
		) !important;
	}
}
#listId {
	width: 100vw;
	margin-top: 10px;
}
#Listitembg {
	background: var(--background);
	backdrop-filter: blur(40px);
	border: 0 !important;
	/* border-color: var(--background) !important; */
	color: var(--color-1);
	border-radius: 10px;
}
#icon {
	color: var(--color-1) !important;
}
.navBrand {
	display: flex;
	align-items: center;
	gap: 5px;
	text-decoration: none;
	color: var(--color-1) !important;
}
#navtext {
	font-size: 36px;
	font-stretch: normal;
	font-style: normal;
	line-height: 1;
	color: var(--text-1);
	font-size: 16px;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: 600;
	letter-spacing: 2px;
}
.ringnavbar {
	/* border: 1px solid #0ff; */
	display: flex;
	align-items: center;
	gap: 20px;
}

.btnnav {
	color: var(--color-2) !important;
	border-radius: var(--bdrad) !important;
	box-shadow: 8px 8px 24px 0rgba (9, 13, 20, 0.4),
		-4px -4px 8px 0rgba (224, 224, 255, 0.04), 0 1px 1px 0rgba (9, 13, 20, 0.4);
	background: var(--btn) !important;
	display: block;
	text-transform: none !important;
	border: none;
	font-size: 18px;
	font-weight: 600 !important;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: -0.4px;
	box-shadow: 0 8px 8px -4pxrgba (69, 121, 245, 0.12),
		0 16px 24px 0rgba (69, 121, 245, 0.24),
		0 2px 4px -1pxrgba (27, 10, 82, 0.12), 0 0 1px 0rgba (69, 121, 245, 0.24);
	padding: 14px 24px !important;
}
.btnnav.aktivbtn {
	box-shadow: 0 8px 8px -4pxrgba (69, 121, 245, 0.12),
		0 16px 24px 0rgba (69, 121, 245, 0.24),
		0 2px 4px -1pxrgba (27, 10, 82, 0.12), 0 0 1px 0rgba (69, 121, 245, 0.24);
	background-image: linear-gradient(114deg, #0fa, #4579f5 53%, #9c42f5);
}
#navtext {
	color: var(--color-1);
	font-weight: 600;
}
#codemylogo {
	width: 180px !important;
}
@media screen and (max-width: 1025px) {
	#codemylogo {
		width: 150px !important;
	}
}

@media screen and (max-width: 770px) {
	#codemylogo {
		width: 100px !important;
	}
}
