* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.beparttext01 {
	color: var(--color-1);
	margin-bottom: 32px !important;
	line-height: 1 !important;
	font-weight: 700 !important;
	text-align: center !important;
}
.beparttext {
	margin-bottom: 32px !important;
	line-height: 1 !important;
	font-weight: 700 !important;
	text-align: center !important;
	color: var(--textbold);
	-webkit-text-stroke: var(--textbold-border-2);
	text-shadow: var(--textbold-shadov);
}
.Gridcard-body {
	background-color: var(--background);

	padding: 2% 0;
}
#iconbepart {
	color: var(--color-1);
}
#Grid-Card {
	display: flex;
	flex-direction: column;
	gap: 20px;
	/* backdrop-filter: blur(40px); */
	/* box-shadow: 8px 8px 24px 0 rgb(9 13 20 / 40%),
		-4px -4px 8px 0 rgb(224 224 255 / 4%), 0 1px 1px 0 rgb(9 13 20 / 40%); */
	box-shadow: var(--surface-shadow) !important;

	border: 1px solid rgba(245, 247, 250, 0.06);
	background-image: linear-gradient(
		102deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);

	border-radius: var(--bdrad) !important;
	padding: 40px;
}
#hrm {
	margin: 35px 0;
}
#TableBody01 {
	max-height: 350px;
	width: 100%;
	overflow-y: scroll;
	/* overflow-x: scroll; */
}
#TableBody {
	height: 1500px !important;
}
#Tablechart {
	height: 1200px !important;
}
#Grid-Card0 {
	display: flex;
	flex-direction: column;
	color: var(--color-1);
	gap: 20px;
	/* backdrop-filter: blur(40px); */
	/* box-shadow: 8px 8px 24px 0 rgb(9 13 20 / 40%),
		-4px -4px 8px 0 rgb(224 224 255 / 4%), 0 1px 1px 0 rgb(9 13 20 / 40%); */
	box-shadow: var(--surface-shadow) !important;

	border: 1px solid rgba(245, 247, 250, 0.06);
	background-image: linear-gradient(
		102deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);

	border-radius: var(--bdrad) !important;
	padding: 24px 34px 48px 40px;
}
#Grid-Card2 {
	display: flex;
	flex-direction: column;

	gap: 20px;
	/* backdrop-filter: blur(40px); */
	/* box-shadow: 8px 8px 24px 0 rgb(9 13 20 / 40%),
		-4px -4px 8px 0 rgb(224 224 255 / 4%), 0 1px 1px 0 rgb(9 13 20 / 40%); */
	box-shadow: var(--surface-shadow) !important;

	background-image: linear-gradient(
		102deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);

	border-radius: var(--bdrad) !important;
	padding: 40px;
}

.Grid-Card-item-img-1 {
	width: 96px;
}

.fullcart0:hover {
	background: var(--bgHover);
}

.fullcart {
	transition: ease 0.5s;
	position: relative;
	top: 0px;
	border: 2px solid transparent;
	/* transition: background 8s; */
	/* background-position: 100%; */
	/* background-repeat: no-repeat; */
	/* background-size: contain !important; */
	border-radius: var(--bdrad) !important;
	background: var(--color-2) !important;
	position: relative;
	top: 0;
	/* -------------- */
	/* backdrop-filter: blur(1000px); */
}
.fullcart:hover {
	position: relative;
	top: -10px;
	border: 2px solid #39b54aff;
}
.fullcart0 {
	border: 1px solid rgba (245, 247, 250, 0.06);
	color: var(--color-1);
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: var(--bdrad) !important;
	background-image: linear-gradient(
		102deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);
	/* background-image: url("https://decimalchain.com/_nuxt/img/fundraising.5bd6b26.png"); */
}

#Text_box {
	width: 100%;
	display: flex;
	/* flex-direction: row; */

	justify-content: space-between !important;
}

#Grid-text-one {
	font-size: 30px;
	font-weight: 700;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.2;
	letter-spacing: -1px;
	color: var(--color-1);
	width: 80%;

	white-space: pre-wrap;
}
#Grid-text-ring,
#text-ring-w {
	font-size: 13px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: 1px;
	text-align: right;
	color: rgba(20, 16, 41, 0.8);
	text-transform: uppercase;
	order: 0;
}
#text-ring-w {
	color: var(--color-1);
}
.Grid-Card-item-bottom-text {
	width: 100%;
	display: flex;
	align-items: center;
	color: var(--color-1);
}
#Boshtex {
	color: var(--color-1);
	font-weight: 700;
	margin-bottom: 32px;
	line-height: 1;
}
#ilovalar {
	width: 100px !important;
}
@media screen and (max-width: 720px) {
	#Text_box {
		display: flex;
		flex-wrap: wrap-reverse;
		justify-content: start;

		flex-direction: column-reverse;
	}
	#fullcart {
		background-size: cover;
		background-image: url("https://decimalchain.com/_nuxt/img/fundraising.56fc92d.png");
	}
}
@media screen and (max-width: 430px) {
	#ilovalar {
		width: 60px !important;
	}
	.Grid-Card-item-img-1 {
		width: 64px;
		height: 64px;
	}
	/* #TableBody {
		width: 100%;
		height: 500px;
	} */
	#Grid-text-one {
		font-size: 20px !important;

		width: 100% !important;
	}
	#iconbepart {
		font-size: 15px;
	}
	#Grid-text-ring {
		font-size: 10px;
	}
}
