/* :root {
  --text-1: #fff;
  --background: #212429;
  --blue: #0bf;
} */
#sortbox {
	background-color: var(--background);

	width: 100%;
}
.LogoCard {
	width: 64px;
}
.exchanges {
	padding-bottom: 70px !important;
	position: relative;
	max-width: 1120px;
	margin: auto;
	padding-left: 32px;
	padding-right: 32px;
	display: grid;
	grid-gap: 34px;
	/* background-color: red; */
}
.FatherSort {
	width: 100%;
	max-width: 1120px;
	margin: auto;
	padding-left: 32px;
	padding-right: 32px;
	display: grid;
	grid-template-rows: auto 34px auto;
	grid-gap: 34px;
}
.exchangeshead,
.exchangestabs {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
}

.exchangestext {
	display: inline-block;
	background-color: rgba(0, 187, 255, 0.03);
	/* border-radius: 12px; */
	border-radius: var(--bdrad) !important;

	padding: 4px 8px;
	color: var(--blue);
	line-height: 18px;
	margin-bottom: 16px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
	color: var(--btn);
}

.exchangestext2 {
	font-size: 36px !important;
	font-weight: 700 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1 !important;
	letter-spacing: -1px !important;

	margin-bottom: 8px !important;
	color: var(--textbold);
	-webkit-text-stroke: var(--textbold-border-2);
	text-shadow: var(--textbold-shadov);
}
.exchangesimg {
	display: inline-block;
	margin-bottom: -4px;
}
.exchangestabs2 {
	display: grid;
	padding: 2px;
	border-radius: var(--bdrad) !important;

	/* border-radius: 12px !important; */
	/* box-shadow: inset 8px 8px 40px 0 rgb(9 13 20 / 40%),
		inset -4px -4px 8px 0 rgb(224 224 255 / 4%),
		inset 0 1px 1px 0 rgb(9 13 20 / 40%); */
	/* background-image: linear-gradient(
		180deg,
		#090d14,
		#282a2e 53%,
		#2d2f33
	) !important; */
	background: var(--color-2);
}
.exchangextabsitems {
	justify-content: center !important;
	padding: 0 36px !important;
	height: 100% !important;
	font-size: 16px !important;
	font-weight: 600 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.75 !important;
	letter-spacing: normal !important;
	text-align: center !important;
	color: var(--color-1) !important;
	transition: 0.15s !important;
	position: relative !important;
	/* padding: 0 3px !important; */
}
.exchangextabsitems:focus {
	margin: 0 1px 0 -1px !important;
	border-radius: var(--bdrad) !important;

	/* border-radius: 10px !important; */
	-webkit-backdrop-filter: blur(40px) !important;

	/* border: 1px solid #000; */
	backdrop-filter: blur(40px) !important;
	box-shadow: 8px 8px 24px 0 rgb(9 13 20 / 40%),
		-4px -4px 8px 0 rgb(224 224 255 / 4%), 0 1px 1px 0 rgba(0, 0, 0, 0.4) !important;
	/* background-color: var(--btn) !important; */
	/* color: var(--color-2) !important; */
}
.extab::after {
	content: "" !important;
	height: 24px !important;
	width: 1px !important;
	position: absolute !important;
	right: 0 !important;
	top: 10px !important;
	border-radius: 0.5px !important;
	/* background-color: var(--btn); */
}

.CardsItemsss {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 32px 30px;
}

.CardItemm {
	background-repeat: no-repeat !important;
	background-size: contain;
	position: relative !important;
	display: inline;

	background-color: var(--color-2) !important;
	padding: 24px !important;
	/* border-radius: 24px !important; */
	border-radius: var(--bdrad) !important;

	transition: 0.25s !important;
	-webkit-backdrop-filter: blur(40px) !important;
	backdrop-filter: blur(40px) !important;
	/* box-shadow: 8px 8px 24px 0 rgb(9 13 20 / 40%),
		-4px -4px 8px 0 rgb(224 224 255 / 4%), 0 1px 1px 0 rgb(9 13 20 / 40%); */
	box-shadow: var(--surface-shadow) !important;

	border: 1px solid rgba(245, 247, 250, 0.06) !important;
}
.CardItemm::before {
	background-image: linear-gradient(
		127deg,
		rgba(245, 247, 250, 0.12),
		rgba(245, 247, 250, 0.06) 52%,
		rgba(245, 247, 250, 0)
	);
}

.CardImgs {
	max-width: 100%;
	border-radius: 12px;
	max-height: 276px;
	min-height: 276px;
	width: -webkit-max-content;
	width: -moz-max-content;
	width: max-content;
}

.cardBottompart {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	gap: 16px;
}
.cardTitle {
	font-size: 20px !important;
	font-weight: 600 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.4 !important;
	letter-spacing: -0.4px !important;
	color: var(--color-1) !important;
}

.cardDesc {
	font-size: 13px !important;
	font-weight: 500 !important;
	font-stretch: normal !important;
	font-style: normal !important;
	line-height: 1.71 !important;
	opacity: 0.6 !important;
	letter-spacing: normal !important;
	color: var(--color-1) !important;
}
.arrowIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	padding: 12px !important;
	border-radius: 12px !important;
	background-color: rgba(224, 224, 255, 0.02) !important;
	cursor: pointer !important;
	transition: 0.25s !important;
	color: var(--color-1);
}
.arrowIcon:hover {
	box-shadow: 8px 8px 24px 0 rgb(9 13 20 / 40%),
		-4px -4px 8px 0 rgb(224 224 255 / 4%), 0 1px 1px 0 rgb(9 13 20 / 40%);
	background-image: linear-gradient(115deg, #2d2f33, #282a2e 51%, #212429 99%);
}
@media screen and (max-width: 900px) {
	.FatherSort {
		overflow: hidden;
	}
	.exchanges {
		margin-bottom: 0px !important;
		padding-bottom: 50px !important;
		overflow: hidden !important;
		padding-left: 16px !important;
		padding-right: 16px !important;
	}
	.exchangestext2 {
		font-size: 24px !important;
	}
}
@media screen and (max-width: 540px) {
	.LogoCard {
		width: 50px;
	}
	.CardsItemsss {
		grid-template-columns: 1fr !important;
		grid-gap: 24px !important;
	}
	.CardItemm {
		position: relative !important;
		grid-template-rows: 160px auto !important;
	}
	.CardImgs {
		max-height: 160px;
		min-height: 160px;
	}
}
.youtube {
	height: 236px !important;
	margin-bottom: 10px;
}
@media screen and (max-width: 500px) {
	.exchangextabsitems {
		width: 62px !important;
		padding: 0px 10px !important;
	}
}
@media screen and (max-width: 780px) {
	.cardTitle {
		font-size: 15px !important;
	}
	.youtube {
		height: 166px !important;
	}
}
@media screen and (max-width: 780px) {
	.CardItemm {
		padding: 10px !important;
	}
}
